import React, { useState, useEffect, useReducer } from "react";
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import Login from './pages/Authentication/Login';
import axios from "axios";
import "./assets/scss/theme.scss"

import MainContext from "./Context/MainContext";
import { getdata } from './store/GetData/reducer';
import './app.css';
import TheCreater from "./roles/TheCreater";
import Axrana from "./roles/Axrana";
import ControlChecking from "./roles/ControlChecking";
import Employee from "./roles/Employee";
import KadrUser from "./roles/KadrUser";

const App = (props) => {



  let location = useLocation();
  let history = useHistory();
  const [username, setusername] = useState("")
  const [auth, setAuth] = useState(false);
  const [role_id, setRole_id] = useState("")
  const [kadr_id, setKadr_id] = useState("")
  const [gstate, dispatch] = useReducer(getdata, []);

  axios.defaults.baseURL = "http://api-reception.tsul.uz/api";
  axios.interceptors.request.use(
    (response) => {
      return response;
    },

    (error) => {
      if (error?.response?.status === 404) {
        <Redirect to="/notfound" />
      }
      if (error?.response?.status === 429) {
        alert("to many request")
      }
    }
  )



  useEffect(() => { localStorage.setItem("path", location?.pathname) }, [location?.pathname])
  console.log("role", role_id)


  useEffect(() => { localStorage.setItem("path", location?.pathname) }, [location?.pathname])




  useEffect(() => {
    const token = localStorage.getItem("token");
    axios({
      url: "/me",
      method: "POST",
      params: {
        token
      }
    }).then((response) => {
      console.log("reponnnnnn", response);
      if (response?.data?.status === 1 && response?.data?.data) {
        setRole_id(String(response?.data?.data?.role_id))
        setKadr_id(String(response?.data?.data?.id))
        setusername(response?.data?.data?.first_name);
        history.push(localStorage.getItem("path"));
      }
      if (response?.data?.data === null) {
        localStorage.removeItem("token")
        history.push("/login")
      }
    })
  }, [])
  const renderRoutes = () => {
    if (kadr_id === "163" && role_id === "777") {
      return <KadrUser />;
    }
    switch (role_id) {
      case "777":
        return <TheCreater />;
      case "1":
        return <Axrana />;
      case "2":
        return <Employee />;
      case "3":
        return <ControlChecking />;
      default:
        return (
          <>
            <Route component={Login} path="/login" exact />
            {!localStorage.getItem("token") && <Redirect to="/login" />}
          </>
        );
    }
  }





  return (
    <React.Fragment >
      <div>
        <MainContext.Provider value={{
          auth,
          setAuth,
          gstate,
          dispatch,
          role_id,
          setRole_id,
          username,
          setusername,
          kadr_id,
          
        }
        } >
          {renderRoutes()}
        </MainContext.Provider>
      </div>
    </React.Fragment>
  )
}

export default App;