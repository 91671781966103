import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Form, Input, Button, DatePicker, Switch, Select } from 'antd';
import axios from 'axios';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import moment from "moment";
import Swal from 'sweetalert2'
import TableLoading from '../Loaders/TableLoading';


const edit_reception = () => {

    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch("/edit_reception/:id")
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [check_users, setCheck_users] = useState([]);
    const [status, setStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        const token = localStorage.getItem("token");
        setIsLoading(true);
        axios({
            url: `/rec/${match?.params?.id}`,
            method: "GET",
            params: {
                token
            }
        }).then((response) => {
            if (response?.data?.status === 1) {
                const res_data = response?.data?.data;
                setData(response?.data?.data)
                form.setFieldsValue({
                    fio: res_data?.fio,
                    date: moment(res_data?.date, 'YYYY-MM-DD'),
                    reason: res_data?.reason,
                    check_user_id: res_data?.check_user_id,
                    description: res_data?.description,
                    status: String(res_data?.status) === "1" ? setStatus(true) : setStatus(false)
                })
            }
            setIsLoading(false);
        })
    }, [])

    useEffect(() => {
        const token = localStorage.getItem("token");
        axios({
            url: "/check-user",
            method: "GET",
            params: {
                token
            }
        }).then((response) => {
            if (response?.data?.status === 1) {
                setCheck_users(response?.data?.data);
            }
        })
    }, [])




    const onSubmit = (values) => {
        values.status = status ? 1 : 0;
        const token = localStorage.getItem("token");
        axios({
            url: `/rec/${data?.id}`,
            method: "PUT",
            params: {
                token
            },
            data: values
        }).then((response) => {
            if (response?.data?.status === 1) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response?.data?.message,
                    showConfirmButton: false,
                    timer: 1500
                }).then(() => {
                    history.goBack()
                })
            }
        })
    }

    if (isLoading) {
        return <TableLoading hh={'70vh'} />
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Card className="card_min_height">
                        <CardBody>
                            <div className="d-flex" style={{cursor:"pointer", width:"max-content"}} onClick={() => history.goBack()}>
                                <i class="fas fa-arrow-left d-block mr-3 mt-1"></i>
                                <h5>{location?.state?.title}</h5>
                            </div>
                            <Form
                                form={form}
                                layout="vertical"
                                name="basic"
                                onFinish={onSubmit}
                                className="mt-3"
                            >
                                <Row>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <Form.Item
                                            label="F.I.SH"
                                            name="fio"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Iltimos F.I.SH ni kiriting',
                                                },
                                            ]}>
                                            <Input placeholder="Familiyani kiriting" />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <Form.Item
                                            label="Tashrif vaqti"
                                            name="date"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Iltimos sharifini kiriting',
                                                },
                                            ]}>
                                            <DatePicker placeholder="Vaqtni tanlang" />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <Form.Item label="Select" name="check_user_id">
                                            <Select placeholder="Ruxsat beruvchini tanlang">
                                                {check_users.length && check_users.map((element, index) => {
                                                    return <Select.Option key={element?.id} value={element?.id}>{element?.name}</Select.Option>
                                                })}

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <Form.Item label="Sababi" name="reason">
                                            <Input.TextArea placeholder="Tashrif sababini kiriting" />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <Form.Item label="Tavsifi" name="description">
                                            <Input.TextArea placeholder="Tavsifini kiriting" />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <Form.Item label="Status" name="status">
                                            <Switch checked={status} onChange={() => setStatus(!status)} />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <div className="d-flex justify-content-end">
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">
                                                    Saqlash
                                            </Button>
                                            </Form.Item>
                                            <Form.Item className="ml-3">
                                                <Button htmlType="reset">
                                                    Tozalash
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}


export default edit_reception;