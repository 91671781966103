import React from 'react';
import { Card, CardBody } from 'reactstrap';
import {
    CircleToBlockLoading
  } from 'react-loadingg';



const TableLoading = ({hh}) => {



    return (
        <React.Fragment>
            <div className="page-content">
                <Card>
                    <CardBody>
                        <div style={{height:hh}}>
                            <CircleToBlockLoading/>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

export default TableLoading;