import { EDIT_DATA, ADD_DATA_ORGANIZATION, ADD_FULL_DATA_ORGANIZATION } from "./actiontypes";



export const initialState = [
    
]


export const getdata = (state=initialState, action) => {
    console.log("action data",action.data)
    switch(action.type){
        case ADD_DATA_ORGANIZATION:
            return action.data
        case EDIT_DATA:
            return {
                ...state.filter(e => e.id === action.id)
            }
        default:
            return state
    }
}
