import React, { useState, useEffect } from "react"
import { Col, Container, Row, Table, Button } from "reactstrap"
import axios from "axios"
import Styles from "./style.module.scss"
import { EyeOutlined } from "@ant-design/icons"
import { Modal, message } from "antd"
import DataLoader from "../Loaders/DataLoaders";
import PaginationComponent from "react-reactstrap-pagination";
const Controller = () => {
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isGet, setIsGet] = useState(false)
  const [isInside, setIsInside] = useState(false)
  const [check, setCheck] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isModalVisibleImage, setIsModalVisibleImage] = useState(false)
  const [show_count, setShow_count] = useState(10);
  const [selected_count, setSelcted_count] = useState(1);
  const [totalItem, setTotalItem] = useState(1);
  const [idd, setIdd] = useState(null);
  const [modaldata, setModaldata] = useState([]);

  useEffect(() => {
    setIsLoading(true)
    const token = localStorage.getItem("token")
    axios({
      url: "/rec",
      method: "GET",
      params: {
        token,
        page: selected_count
      },
    }).then(response => {
      setIsLoading(false)
      if (response?.data?.status === 0) {
        alert(response?.data?.message)
      } else {
        setData(response?.data?.data?.data)
        setTotalItem(response?.data?.data?.total)
      }
    })
  }, [isGet, selected_count])

  const permision = id => {
    setCheck(!check)
    const token = localStorage.getItem("token")
    const formdata = new FormData()
    formdata.append("checked", 1)
    axios({
      url: `/check/${id}`,
      method: "POST",
      params: {
        token,
      },
      data: formdata,
    }).then(response => {
      console.log("checked Item", response)
      setIsGet(!isGet)
      setIsModalVisible(false)
      message.success('Ruxsat berildi');
    })
  }
  const notAllow = id => {
    setCheck(!check)
    const token = localStorage.getItem("token")
    const formdata = new FormData()
    formdata.append("checked", 0)
    axios({
      url: `/check/${id}`,
      method: "POST",
      params: {
        token,
      },
      data: formdata,
    }).then(response => {
      console.log("checked Item", response)
      setIsGet(!isGet)
      setIsModalVisible(false)
      message.warning('Ruxsat berilmadi');
    })
  }



  const handleSelected = (selectedPage) => {
    setSelcted_count(selectedPage);
  }
  const showModal = onedata => {
    setIdd(onedata.id)
    setModaldata(onedata)
    setIsModalVisible(true)
    setFilteredData(data.filter(i => i.id === onedata.id))
  }


  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg="12">
            <div className="table-responsive">
              {!isLoading ? (
                <Table className="project-list-table table-nowrap table-centered table-borderless">
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: "100px" }}>
                        #
                      </th>
                      <th scope="col">F.I.Sh</th>
                      <th scope="col">Sababi</th>
                      <th scope="col" className={Styles.center}>Sanasi</th>
                      <th scope="col" className={Styles.center}>
                        Amallar
                      </th>
                      <th scope="col">Malumotlari</th>
                      <th scope="col" className={Styles.center}>Kirdi / Chiqdi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item, index) => (
                      <tr
                        key={item.id}
                        className={`${item.checked === 0
                          ? Styles.bg_red
                          : item.checked === 1
                            ? Styles.bg_green
                            : Styles.bg_warning
                          }`}
                      >
                        <td>{show_count === "all" ? index + 1 : (show_count * (selected_count - 1) + index + 1)}</td>
                        <td>
                          <h5 className={Styles.color_black} onClick={() => showModal(item.id)}>{item.fio}</h5>
                        </td>
                        <td>{item.reason}</td>
                        <td className={Styles.center}>
                          {item.date}
                        </td>
                        <td className="text-center font-size-14 p-0">
                          <button
                            disabled={item.checked === 1 ? true : false}
                            id="gg"
                            className="btn btn-light text-dark Mybutton mr-2"
                            onClick={() => permision(item.id)}
                          >
                            Ruxsat berish
                          </button>
                          <button
                            disabled={(item.checked === 0) || (item.come !== null) ? true : false}
                            id="gg"
                            className="btn btn-light text-dark Mybutton "
                            onClick={() => notAllow(item.id)}
                          >
                            Bekor qilish
                          </button>
                        </td>
                        <td className="text-center">
                          <EyeOutlined
                            className={Styles.eye_icon}
                            onClick={() => showModal(item)}
                          />
                        </td>
                        <td className={Styles.center}>
                          {item.come?.slice(0, 16)}
                          {item.gone ? " / " + item.gone?.slice(0, 16) : " "}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <DataLoader />
              )}
            </div>
          </Col>
        </Row>
        {data.length > 0 && (
          <Row>
            <Col xl={2} lg={2} md={3} sm={12} xs={12} className="px-0">
              {/* <div className=" d-flex justify-content-between align-items-center font-weight-bold">
                <span>Show</span>
                <select
                  className="custom-select mx-2"
                  value={show_count}
                  onChange={e => setShow_count(e.target.value)}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                  <option value="all">all</option>
                </select>
                <span>entries</span>
              </div> */}
            </Col>
            <Col
              xl={10}
              lg={10}
              md={8}
              sm={12}
              xs={12}
              className="px-0 d-flex justify-content-end"
            >
              <div className="pull-right">
                <PaginationComponent
                  totalItems={totalItem}
                  pageSize={10}
                  onSelect={handleSelected}
                  maxPaginationNumbers={Math.ceil(totalItem / 10)}
                  defaultActivePage={1}
                />
              </div>
            </Col>
          </Row>
        )}
        <Modal
        centered
          title="Keluvchi ma'lumotlari"
          visible={isModalVisible}
          onCancel={()=>setIsModalVisible(false)}
          width={600}
          footer={[
            <button
              disabled={modaldata.checked === 1 ? true : false}
              className="btn btn-outline-success" onClick={() => permision(idd)} >
              Ruxsat Berish
            </button>,
            <button
              disabled={(modaldata.checked === 0) || (modaldata.come !== null) ? true : false}
              className="btn btn-outline-danger ml-2" onClick={() => notAllow(idd)} >
              Bekor qilish
              </button>,
            <button onClick={()=>setIsModalVisible(false)} className="btn btn-outline-dark ml-2">
              Yopish
            </button>,
          ]}
        >
          <div>
            <div className="row">
              <div className="col-6">
                <div>
                  <span className="font-weight-bold">F.I.SH</span>
                  <p>{filteredData[0]?.fio}</p>
                </div>

                <div>
                  <span className="font-weight-bold">Tasnifi</span>
                  <p>{filteredData[0]?.description}</p>
                </div>
                <div>
                  <span className="font-weight-bold">Sababi</span>
                  <p>{filteredData[0]?.reason}</p>
                </div>
              </div>
              <div className="col-6 right-text">
                <img onClick={()=>setIsModalVisibleImage(true)} style={{cursor:"pointer"}} className="w-100" src={"http://api-reception.tsul.uz" + filteredData[0]?.photo_come} alt="Rasm topilmadi" />
              </div>
            </div>
          </div>
        </Modal>

      {/* open image modal */}

      <Modal centered zIndex={10000} bodyStyle={{padding:"0"}} className="p-0" closeIcon={<i className={`${Styles.closeButton} fas fa-times text-white`}></i>} width={1000} title="" visible={isModalVisibleImage} onCancel={()=>setIsModalVisibleImage(false)} footer={false} >
           <img src={"http://api-reception.tsul.uz" + filteredData[0]?.photo_come} className="w-100" alt="" />
       </Modal>

      </Container>
    </div>
  )
}

export default Controller
