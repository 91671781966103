import React from "react"
import ReactDOM from "react-dom"
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter, useHistory } from "react-router-dom"
import "./i18n"
import 'sweetalert2/src/sweetalert2.scss'
import { Provider } from "react-redux"
import store from "./store"


const app =  (

  
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
