import React from 'react';



const DataLoader = (props) => {



    return (
        <div style={{ height: "50vh", backgroundColor: "#FFFFFF", borderBottom:"1px solid grey", marginBottom:"20px"}}>
            <div className="d-flex justify-content-center align-items-center h-100">
                <i className="bx bx-loader bx-spin font-size-25 align-middle mr-2 text-success" style={{ fontSize: "40px" }} />
            </div>
        </div>
    )
}

export default DataLoader;