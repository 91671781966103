import PropTypes from "prop-types"
import React, { useState, useEffect, useContext } from "react"
import { Row, Col, Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"
import axios from "axios"
import MainContext from "../../Context/MainContext"

const Navbar = props => {


  const { role_id, kadr_id } = useContext(MainContext);

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [])
  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  if (role_id === "1") {
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={props.leftMenu}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/" className="nav-link arrow-none">
                      <i className="bx bx-home-circle mr-2"></i>
                      {props.t("Asosiy sahifa")} {props.menuOpen}
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    )
  }

  if (role_id === "2") {
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={props.leftMenu}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/" className="nav-link arrow-none">
                      <i className="bx bx-home-circle mr-2"></i>
                      {props.t("Asosiy sahifa")} {props.menuOpen}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/reception" className="nav-link arrow-none">
                      <i className="bx bx-user mr-2"></i>
                      Mehmon
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    )
  }



  if (role_id === "3") {
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={props.leftMenu}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/" className="nav-link arrow-none">
                      <i className="bx bx-home-circle mr-2"></i>
                      {props.t("Asosiy sahifa")} {props.menuOpen}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/controll" className="nav-link arrow-none">
                      <i className="bx bx-user mr-2"></i>
                      {props.t("Tekshiruvchi  ")}
                    </Link>
                  </li>
                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    )
  }
  if (kadr_id === "163" && role_id === "777") {
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={props.leftMenu}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/" className="nav-link arrow-none">
                      <i className="bx bx-home-circle mr-2"></i>
                      {props.t("Asosiy sahifa")} {props.menuOpen}
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/users" className="nav-link arrow-none">
                      <i className="bx bx-user mr-2"></i>
                      {props.t("Foydalanuvchilar")}
                    </Link>
                  </li>

                </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to="/" className="nav-link arrow-none">
                    <i className="bx bx-home-circle mr-2"></i>
                    {props.t("Asosiy sahifa")} {props.menuOpen}
                  </Link>
                </li>

                <li className="nav-item">
                  <Link to="/users" className="nav-link arrow-none">
                    <i className="bx bx-user mr-2"></i>
                    {props.t("Foydalanuvchilar")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/reception" className="nav-link arrow-none">
                    <i className="bx bx-user mr-2"></i>
                    Mehmon
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/security" className="nav-link arrow-none">
                    <i className="bx bx-user mr-2"></i>
                    Qo'riqlash xizmati
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/controll" className="nav-link arrow-none">
                    <i className="bx bx-user mr-2"></i>
                   Nazorat
                  </Link>
                </li>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)
