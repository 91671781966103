import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Form, Input, Button, DatePicker, Switch, Select } from 'antd';
import Swal from 'sweetalert2'
import axios from 'axios';
import { useHistory } from 'react-router';



const add_reception = () => {


    const history = useHistory();

    const [form] = Form.useForm();

    const [check_users, setCheck_users] = useState([]);


    useEffect(() => {
        const token = localStorage.getItem("token");
        axios({
            url: "/check-user",
            method: "GET",
            params: {
                token
            }
        }).then((response) => {
            if (response?.data?.status === 1) {
                setCheck_users(response?.data?.data);
            }
        })
    }, [])



    const onSubmit = (values) => {

        values.status = values.status ? 1 : 0;



        const token = localStorage.getItem("token");

        axios({
            url: "/rec",
            method: "POST",
            params: {
                token
            },
            data: values
        }).then((response) => {
            if (response?.data?.status === 1) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response?.data?.message,
                    showConfirmButton: false,
                    timer: 1500
                }).then(() => {
                    history.goBack()
                })
            }
        })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Card className="card_min_height">
                        <CardBody>
                            <div className="d-flex" style={{ cursor: "pointer", width: "max-content" }} onClick={() => history.goBack()}>
                                <i class="fas fa-arrow-left d-block mr-3 mt-1"></i>
                                <h5>Bekor qilish</h5>
                            </div>
                            <Form
                                form={form}
                                layout="vertical"
                                name="basic"
                                onFinish={onSubmit}
                                className="mt-3"
                                initialValues={{ status: true }}
                            >
                                <Row>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <Form.Item
                                            label="F.I.SH"
                                            name="fio"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Iltimos F.I.SH ni kiriting',
                                                },
                                            ]}>
                                            <Input placeholder=" F.I.SH ni kiriting" />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <Form.Item
                                            label="Tashrif vaqti"
                                            name="date"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Iltimos tashrif vaqtini tanlang',
                                                },
                                            ]}>
                                            <DatePicker placeholder="Vaqtni tanlang" />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <Form.Item label="Select" name="check_user_id">
                                            <Select placeholder="Ruxsat beruvchini tanlang">
                                                {check_users.length && check_users.map((element, index) => {
                                                    return <Select.Option key={element?.id} value={element?.id}>{element?.name}</Select.Option>
                                                })}

                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <Form.Item
                                            label="Sababi"
                                            name="reason"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Iltimos sababini kiriting',
                                                },
                                            ]}>
                                            <Input.TextArea placeholder="Tashrif sababini kiriting" />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <Form.Item label="Tavsifi" name="description">
                                            <Input.TextArea placeholder="Tavsifini kiriting" />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <Form.Item label="Status" name="status">
                                            <Switch defaultChecked />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                                        <div className="d-flex justify-content-end">
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">
                                                    Saqlash
                                            </Button>
                                            </Form.Item>
                                            <Form.Item className="ml-3">
                                                <Button htmlType="reset">
                                                    Tozalash
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}


export default add_reception;