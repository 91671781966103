import React, { useState, useEffect, useContext } from "react"
import { Card, CardBody, Container, Badge, Row, Col } from "reactstrap"
import PaginationComponent from "react-reactstrap-pagination"
import axios from "axios"
import { NavLink, useHistory } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table"
import styles from "./style.module.scss"
import DataLoader from "../Loaders/DataLoaders"
import { Modal, Form, Switch } from "antd"
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
const allUsers = props => {
  const [data, setData] = useState([])
  const history = useHistory()
  const [statusSelectId, setStatusSelectId] = useState("all")

  const [searchVal, setSearchVal] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [show_count, setShow_count] = useState(10)
  const [selected_count, setSelcted_count] = useState(1)
  const [totalItem, setTotalItem] = useState(1)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [userById, setUserById] = useState([])
  const [idd, setIdd] = useState(null)
  const [password, setPassword] = useState("******")
  const [showpassword, setShowpassword] = useState(true)
  const [check, setCheck] = useState(true)
  const [checked1, setCheched] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [count, setCount] = useState(null)

  useEffect(() => {
    const token = localStorage.getItem("token")
    setIsLoading(true)
    axios({
      url: "/user",
      method: "GET",
      params: {
        token,
        page: selected_count
      },
    }).then(response => {
      setData(response?.data?.data?.data)
      setIsLoading(false);
      setTotalItem(response?.data?.data.total)
      setCount(response?.data?.count)
    })
  }, [check, selected_count])

  const getUserByID = id => {
    setIdd(id)
    setUserById(data.filter(e => e.id === id))
    data.filter(e => e.id === id)[0]?.status == 1 ? setCheched(true) : setCheched(false)
  }

  const getUserPassword = () => {
    var ahrorbekformdata = new FormData()
    ahrorbekformdata.append('user_id', idd);
    const token = localStorage.getItem("token")
    axios({
      url: `/password-show`,
      method: "POST",
      params: {
        token,
      },
      data: ahrorbekformdata
    }).then(response => {
      response?.data?.data?.password ? setPassword(response?.data?.data?.password) || setShowpassword(false) : setPassword(response?.data?.message) || setShowpassword(true)
    })
  }


  // select page for pagination
  const handleSelected = selectedPage => {
    setSelcted_count(selectedPage)
  }
  const deleteUser = (id) => {
    setShowpassword(true)
    Swal.fire({
      title: 'Foydalanuvchini o\'chirmoqchimisz?',
      text: "",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "O'chirish",
      cancelButtonText: 'Bekor qilish',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (data.length === 1 && selected_count > 1) {
          setSelcted_count(selected_count - 1);
        }
        const token = localStorage.getItem("token");
        axios({
          url: `/user/${id}`,
          method: "DELETE",
          params: {
            token,
          }
        }).then((response) => {
          setCheck(!check)
          response?.data?.status === 1 ?
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: response?.data?.message,
              showConfirmButton: false,
              timer: 1000
            }) : ""
        });
      }
    })
    setIsModalVisible(false)
  }

  const showModal = () => {
    setShowpassword(true)
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setShowpassword(true)
    setIsModalVisible(false)
    setPassword("******")
  }
  const handleCancel = () => {
    setShowpassword(true)
    setIsModalVisible(false)
    setUserById([])
    setPassword("******")
  }
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>

              <div className="d-flex justify-content-between">
                <h5>Foydalanuvchilar</h5>
                <NavLink to="/add_user">
                  <button
                    type="button"
                    className="btn btn-primary w-lg waves-effect waves-light"
                  >
                    <i
                      className="bx bx-plus mr-2"
                    ></i>
                    Qo'shish
                  </button>
                </NavLink>
              </div>
              <Row className="my-2">
                <Col xl={9} lg={10} md={12} sm={12}>
                  <select
                    className="custom-select"
                    value={statusSelectId}
                    style={{ width: "200px" }}
                    onChange={e => setStatusSelectId(e.target.value)}
                  >
                    <option value="all">Barchasi</option>
                    <option value="1">Faol</option>
                    <option value="0">Faolmas</option>
                  </select>
                </Col>
                <Col
                  xl={3}
                  lg={2}
                  md={12}
                  sm={12}
                  className="d-flex justify-content-end"
                >
                  <form className="search_box_table">
                    <i className="bx bx-search text-secondary"></i>
                    <input
                      type="text"
                      className="table_search_input"
                      placeholder="search..."
                      value={searchVal}
                      onChange={e => setSearchVal(e.target.value)}
                    />
                  </form>
                </Col>
              </Row>
              <Table
                id="tech-companies-1"
                className="table table-striped table-bordered"
              >
                <Thead className="font-size-14">
                  <Tr>
                    <Th data-priority="3">№</Th>
                    <Th data-priority="3">F.I.SH</Th>
                    <Th data-priority="3">Department</Th>
                    <Th data-priority="1">Role</Th>
                    <Th data-priority="3">Status</Th>
                    <Th data-priority="6">Amallar</Th>
                  </Tr>
                </Thead>
                {!isLoading && (
                  <Tbody>
                    {data?.length > 0 &&
                      data?.map((element, index) => {
                        return (
                          <Tr key={element.id}>
                            <Th className="">
                              {show_count === "all"
                                ? index + 1
                                : show_count * (selected_count - 1) + index + 1}
                            </Th>
                            <Th className="table_body link_show_by_name">
                            {element?.first_name+" "+element?.last_name+" "+element?.middle_name}
                            </Th>
                            <Th className="table_body">{element?.department?.name}</Th>
                            <Th className="table_body">{element?.role_id===1?"Qo'riqlash vizmati": element?.role_id===2? "Foydalanuvchi":"navbarhi"}</Th>
                            <Th
                              className="table_body text-center"
                              style={{ width: "80px" }}
                            >
                              <Badge
                                color={
                                  element.status === 1 ? "success" : "danger"
                                }
                                className="py-1 px-2 badge badge-pill"
                              >
                                {element.status === 1
                                  ? " faol "
                                  : " faolmas "}
                              </Badge>
                            </Th>
                            <Th
                              className="table_body "
                              style={{ width: "80px" }}
                            >
                              <div className="func_buttons">
                                <span onMouseDown={() => getUserByID(element.id)}
                                  onClick={showModal}>
                                  <i className="bx bx-show-alt font-size-24 "></i>
                                </span>
                                <NavLink
                                  to={`/edit_user/${element.id}`}
                                >
                                  <span>
                                    <i className="bx bx-edit font-size-20 text-muted"></i>
                                  </span>
                                </NavLink>
                                <span
                                  onClick={() => deleteUser(element.id)}
                                >
                                  <i className="bx bx-trash font-size-22 text-danger"></i>
                                </span>
                              </div>
                            </Th>
                          </Tr>
                        )
                      })}
                  </Tbody>
                )}
              </Table>
              {!isLoading &&
                data.length === 0 &&
                searchVal === "" &&
                statusSelectId === "all" ? (
                <div
                  className="d-flex justify-content-center align-items-center text-center"
                  style={{ height: "50vh", width: "100%" }}
                >
                  <div>
                    <h5 className="mb-3">Foydalanuvchi qo'shilmagan.</h5>
                    <NavLink to="/add_user">
                      <button className="btn btn-success">
                        <i className="bx bx-plus mr-2"></i>
                        Yangi foydalanuvchi qo'shish
                      </button>
                    </NavLink>
                  </div>
                </div>
              ) : null}
              {isLoading && <DataLoader />}
              {(searchVal !== "" &&
                data.length === 0 &&
                statusSelectId === "all") ||
                (statusSelectId !== "all" && data.length === 0 && (
                  <div
                    className="d-flex justify-content-center align-items-center text-center"
                    style={{ height: "60vh", width: "100%" }}
                  >
                    <div>
                      <h5 className="mb-3 display-4 text-secondary">
                        Ma'lumot topilmadi !
                      </h5>
                    </div>
                  </div>
                ))}
              {data.length > 0 && (
                <Row>
                  <Col xl={2} lg={2} md={3} sm={12} xs={12} className="px-0">
                    <div className=" d-flex justify-content-between align-items-center font-weight-bold">
                      <span>Show</span>
                      <select
                        className="custom-select mx-2"
                        value={show_count}
                        onChange={e => setShow_count(e.target.value)}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        <option value="all">all</option>
                      </select>
                      <span>entries</span>
                    </div>
                  </Col>
                  <Col xl={10} lg={10} md={8} sm={12} xs={12} className="px-0 d-flex justify-content-end"
                  >
                    <div>
                      <PaginationComponent
                        totalItems={totalItem}
                        pageSize={10}
                        onSelect={handleSelected}
                        maxPaginationNumbers={Math.ceil(totalItem / 10)}
                        defaultActivePage={selected_count}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Container>
        {/* info modal */}
        <Modal
          title="Foydalanuvchi Haqida"
          visible={isModalVisible}
          onCancel={handleCancel}
          width={600}
          footer={[
            <Form.Item className={styles.modal_switch}>
              <Switch checked={checked1} />
              <span className="modal-label ml-2">{checked1 ? "Faol" : "Faol emas"}</span>
            </Form.Item>,
            <button className="btn btn-outline-danger" onClick={() => deleteUser(idd)}>
              Foydalanuvchini
              o'chirish
            </button>,
            <NavLink
              to={`/edit_user/${userById[0]?.id}`}
            >
              <button className="btn btn-light ml-2" onClick={handleCancel}>
                O'zgartirish
              </button>
            </NavLink>,
            <button onClick={handleOk} className="btn btn-light ml-2">
              Yopish
            </button>,
          ]}
        >
          <div className={styles.modal_about}>
            <div className="row">
              <div className="col-sm-6 col-12">
                <div>
                  <span>F.I.SH</span>
                  <p>
                    {userById[0]?.first_name +
                      " " +
                      userById[0]?.last_name +
                      " " +
                      userById[0]?.middle_name}
                  </p>
                </div>
                <div>
                  <span>Status</span>
                  <p>{userById[0]?.status === 1 ? "Faol" : "Faol emas"}</p>
                </div>
                <div>
                  <span>Tizimga kirish uchun login</span>
                  <p>{userById[0]?.username}</p>
                </div>
              </div>
              <div className="col-sm-6 col-12 right-text">
                <div>
                  <span>Jinsi</span>
                  <p>{userById[0]?.gender === 1 ? "Erkak" : "ayol"}</p>
                </div>
                <div>
                  <span>Rol</span>
                  <p>{userById[0]?.role_id === 1 ? "Qo'riqlash vizmati" : userById[0]?.role_id === 2 ? "Foydalanuvchi" : "navbarhi"}</p>
                </div>
                <div>
                  <span>Tizimga kirish uchun parol</span>
                  <p>{password}</p>
                </div>
                {
                  !showpassword ?
                    <span ><EyeOutlined className="font-size-20" /></span> :
                    <span onClick={getUserPassword} className={styles.eye_icon_default}><EyeInvisibleOutlined /></span>
                }
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}

export default withTranslation()(allUsers)
