import Dashboard from "../pages/Dashboard/index";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import Organizations from '../pages/Users/index';
import AddUser from "../pages/Users/add_users";
import EditUser from "../pages/Users/edit_user"
import Security from "../pages/Security";
// import Controller from "pages/Controll/controll"
import Reception from "../pages/Reception";
import MyReception from "../pages/Reception/myindex";
import add_reception from "../pages/Reception/add_reception";
import edit_reception from "../pages/Reception/edit_reception";
import Controller from "../pages/Controll/controll";
import ProfilePage from "../pages/ProfilePage/ProfilePage";


const kadrRoutes = [
  {
    path: "/",
    component: Dashboard
  },
  {
    path: "/profile",
    component: ProfilePage
  },
  {
    path: "/users",
    component: Organizations
  },
  {
    path: "/add_user",
    component: AddUser
  },

  {
    path: `/edit_user/:id`,
    component: EditUser
  },
]


const userRoutes = [
  {
    path: "/",
    component: Dashboard
  },
  {
    path: "/profile",
    component: ProfilePage
  },
  {
    path: "/users",
    component: Organizations
  },
  {
    path: "/add_user",
    component: AddUser
  },

  {
    path: `/edit_user/:id`,
    component: EditUser
  },
  {
    path: "/security",
    component: Security
  },
  {
    path: "/reception",
    component: Reception
  },
  {
    path: "/controll",
    component: Controller
  },
  {
    path: "/add_reception",
    component: add_reception
  },
  {
    path: "/edit_reception/:id",
    component: edit_reception
  },
]

const axranaRoutes = [
  {
    path: "/",
    component: Security
  },
  {
    path: "/profile",
    component: ProfilePage
  }
]
const controlCheckRoutes = [
  {
    path: "/",
    component: Dashboard
  },
  {
    path: "/profile",
    component: ProfilePage
  },
  {
    path: "/controll",
    component: Controller
  },
]

const employeeRoutes = [
  {
    path: "/",
    component: Dashboard
  },
  {
    path: "/profile",
    component: ProfilePage
  },
  {
    path: "/reception",
    component: MyReception
  },
  {
    path: "/add_reception",
    component: add_reception
  },
  {
    path: "/edit_reception/:id",
    component: edit_reception
  },
]

const authRoutes = [
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
]

export { userRoutes, authRoutes, axranaRoutes, controlCheckRoutes, employeeRoutes, kadrRoutes }
