export const isChecked = (val) => {
    if (val === 1 || val === "1") {
        return {
            title: "Ruxsat berildi",
            color: "success"
        }
    } else if (val === 0 || val === "0") {
        return {
            title: "Bekor qilindi",
            color: "danger"
        }
    } else {
        return {
            title: "Ko'rilmadi",
            color: "warning"
        }
    }
}