import axios from 'axios';
import Swal from 'sweetalert2';



export const delete_data = async (path) => {
    try {
        const token = localStorage.getItem("token");
        const response = await axios({ url: path, method: "DELETE", params: {  token }});
        const data = await response?.data;

        if(data?.status === 1){
            return  Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: response?.data?.message,
                showConfirmButton: false,
                timer: 1500
            })
        }

    } catch (error) {
        console.log("DELETE DATA ERR: ", error);
    }
}