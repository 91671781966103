import React, { useState, useEffect } from "react"
import { Card, CardBody, Container, Badge, Row, Col, Modal } from "reactstrap"
import PaginationComponent from "react-reactstrap-pagination"
import axios from "axios"
import { NavLink, useHistory } from "react-router-dom"
import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table"
import styles from "./style.module.scss"
import DataLoader from "../Loaders/DataLoaders"
import { delete_data } from "../../services/api_services"
import ShowReception from "./show_reception"
import { isChecked } from "../../services/additional_functions/index"

const Reception = () => {
  const history = useHistory()
  const [statusSelectId, setStatusSelectId] = useState("all")

  const [searchVal, setSearchVal] = useState("")
  const [updateStateIndex, setUpdateStateIndex] = useState(false)
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [show_count, setShow_count] = useState(10)
  const [selected_count, setSelcted_count] = useState(1)
  const [totalItem, setTotalItem] = useState(1)
  const [isOpen, setIsOpen] = useState(false)
  const [showData, setShowdata] = useState({})

  useEffect(() => {
    const token = localStorage.getItem("token")
    setIsLoading(true)
    axios({
      url: "/rec",
      method: "GET",
      params: {
        token,
        page: selected_count,
      },
    }).then(response => {
      console.log("reseption data", response)
      if (response?.data?.status === 1) {
        setData(response?.data?.data?.data)
      }
      setTotalItem(response?.data?.data?.total)
      setIsLoading(false)
    })
  }, [updateStateIndex, selected_count])

  // select page for pagination
  const handleSelected = selectedPage => {
    setSelcted_count(selectedPage)
  }

  const deleteItem = async path => {
    await delete_data(path).then(() => {
      setUpdateStateIndex(!updateState)
    })
  }

  const filterData = id => {
    const newData = data.filter(e => e.id === id)
    setShowdata(newData[0])
    if (showData) {
      setIsOpen(true)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card className="card_min_height">
            <CardBody>
              <div className="d-flex justify-content-between">
                <h5>Reception</h5>
                <NavLink to="/add_reception">
                  <button
                    type="button"
                    className="btn btn-primary w-lg waves-effect waves-light"
                  >
                    <i className="bx bx-plus mr-2"></i>Qo'shish
                  </button>
                </NavLink>
              </div>

              <Modal size="lg" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} centered>

                <ShowReception
                  updateStateIndex={updateStateIndex}
                  setUpdateStateIndex={setUpdateStateIndex}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  data={showData}
                />
              </Modal>


              <Row className="my-2">
                <Col xl={9} lg={10} md={12} sm={12}>
                  <select
                    className="custom-select"
                    value={statusSelectId}
                    style={{ width: "200px" }}
                    onChange={e => setStatusSelectId(e.target.value)}
                  >
                    <option value="all">Barchasi</option>
                    <option value="1">Faol</option>
                    <option value="0">Faolmas</option>
                  </select>
                </Col>
                <Col
                  xl={3}
                  lg={2}
                  md={12}
                  sm={12}
                  className="d-flex justify-content-end"
                >
                  <form className="search_box_table">
                    <i className="bx bx-search text-secondary"></i>
                    <input
                      type="text"
                      className="table_search_input"
                      placeholder="search..."
                      value={searchVal}
                      onChange={e => setSearchVal(e.target.value)}
                    />
                  </form>
                </Col>
              </Row>
              <Table
                id="tech-companies-1"
                className="table table-striped table-bordered"
              >
                <Thead className="font-size-14">
                  <Tr>
                    <Th data-priority="3" className="thead_cc">
                      <b>№</b>
                    </Th>
                    <Th data-priority="1" className="thead_cc">
                      <b>F.I.SH</b>
                    </Th>
                    <Th data-priority="3" className="thead_cc">
                      <b>Sanasi</b>
                    </Th>
                    <Th data-priority="3" className="thead_cc">
                      <b>Sababi</b>
                    </Th>
                    <Th data-priority="3" className="thead_cc">
                      <b>Tekshirilganligi</b>
                    </Th>
                    <Th data-priority="6" className="thead_cc">
                      <b>Holati</b>
                    </Th>
                    <Th data-priority="6" className="thead_cc">
                      <b>Amallar</b>
                    </Th>
                  </Tr>
                </Thead>
                {!isLoading && (
                  <Tbody>
                    {data.length > 0 &&
                      data.map((element, index) => {
                        return (
                          <Tr key={element.id}>
                            <Th className="">
                              {show_count === "all"
                                ? index + 1
                                : show_count * (selected_count - 1) + index + 1}
                            </Th>
                            <Th className="table_body">{element?.fio}</Th>
                            <Th className="table_body">{new Date(element?.date).toLocaleDateString('RU-ru')}</Th>
                            <Th className="table_body">{element?.reason}</Th>
                            <Th
                              className="table_body text-center"
                              style={{ width: "80px" }}
                            >
                              <Badge
                                color={isChecked(element?.checked)?.color}
                                className="py-1 px-2 badge badge-pill"
                              >
                                {isChecked(element?.checked)?.title}
                              </Badge>
                            </Th>
                            <Th
                              className="table_body text-center"
                              style={{ width: "80px" }}
                            >
                              <Badge
                                color={
                                  Number(element?.status) === 1
                                    ? "success"
                                    : "danger"
                                }
                                className="py-1 px-2 badge badge-pill"
                              >
                                {Number(element.status) === 1
                                  ? " faol "
                                  : " faolmas "}
                              </Badge>
                            </Th>

                            <Th
                              className="table_body "
                              style={{ width: "80px" }}
                            >
                              <div className="func_buttons">
                                <span onClick={() => filterData(element?.id)}>
                                  <i className="bx bx-show-alt font-size-24 "></i>
                                </span>
                                {element?.checked === 2 ? (
                                  <>
                                    <NavLink
                                      to={{
                                        pathname: `/edit_reception/${element.id}`,
                                        state: {
                                          title: element?.fio,
                                        },
                                      }}
                                    >
                                      <span>
                                        <i className="bx bx-edit font-size-20 text-muted"></i>
                                      </span>
                                    </NavLink>
                                    <span
                                      onClick={() =>
                                        deleteItem(`/rec/${element?.id}`)
                                      }
                                    >
                                      <i className="bx bx-trash font-size-22 text-danger"></i>
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span>
                                      <span>
                                        <i
                                          className={`bx bx-edit font-size-20 text-secondary ${styles.disAbled}`}
                                        ></i>
                                      </span>
                                    </span>

                                    <span>
                                      <i
                                        className={`bx bx-trash font-size-22 text-secondary ${styles.disAbled}`}
                                      ></i>
                                    </span>
                                  </>
                                )}
                              </div>
                            </Th>
                          </Tr>
                        )
                      })}
                  </Tbody>
                )}
              </Table>
              {!isLoading &&
                data.length === 0 &&
                searchVal === "" &&
                statusSelectId === "all" ? (
                <div
                  className="d-flex justify-content-center align-items-center text-center"
                  style={{ height: "50vh", width: "100%" }}
                >
                  <div>
                    <h5 className="mb-3">Reseption qo'shilmagan.</h5>
                    <NavLink to="/add_reception">
                      <button className="btn btn-success">
                        <i className="bx bx-plus mr-2"></i>
                        Yangi reseption qo'shish
                      </button>
                    </NavLink>
                  </div>
                </div>
              ) : null}
              {isLoading && <DataLoader />}
              {(searchVal !== "" &&
                data.length === 0 &&
                statusSelectId === "all") ||
                (statusSelectId !== "all" && data.length === 0 && (
                  <div
                    className="d-flex justify-content-center align-items-center text-center"
                    style={{ height: "60vh", width: "100%" }}
                  >
                    <div>
                      <h5 className="mb-3 display-4 text-secondary">
                        Ma'lumot topilmadi !
                      </h5>
                    </div>
                  </div>
                ))}
              {data.length > 0 && (
                <Row>
                  <Col xl={2} lg={2} md={3} sm={12} xs={12} className="px-0">
                    <div className=" d-flex justify-content-between align-items-center font-weight-bold">
                      <span>Show</span>
                      <select
                        className="custom-select mx-2"
                        value={show_count}
                        onChange={e => setShow_count(e.target.value)}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        <option value="all">all</option>
                      </select>
                      <span>entries</span>
                    </div>
                  </Col>
                  <Col
                    xl={10}
                    lg={10}
                    md={8}
                    sm={12}
                    xs={12}
                    className="px-0 d-flex justify-content-end"
                  >
                    <div>
                      <PaginationComponent
                        totalItems={totalItem}
                        pageSize={10}
                        onSelect={handleSelected}
                        maxPaginationNumbers={Math.ceil(totalItem / 10)}
                        defaultActivePage={1}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Reception
