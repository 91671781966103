import PropTypes from 'prop-types'
import React from "react"

import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { authRoutes,employeeRoutes } from "../routes/allRoutes"

// Import all middleware
import Authmiddleware from "../routes/middleware/Authmiddleware"

// layouts Format
import HorizontalLayout from "../components/HorizontalLayout"
import NonAuthLayout from "../components/NonAuthLayout"

// Import scss
import "../assets/scss/theme.scss"


const Employee = props => {
  const getLayout = () => HorizontalLayout;

  const Layout = getLayout()
  return (
    <React.Fragment>
          <Switch>
            {authRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
              />
            ))}

            {employeeRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={HorizontalLayout}
                component={route.component}
                key={idx}
                exact
              />
            ))}
          </Switch>
    </React.Fragment>
  )
}

Employee.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(Employee)
