import React, { useContext, useState } from "react"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { Link, useHistory } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
import profile from "../../assets/images/profile-img.png"
import axios from "axios"
import MainContext from "../../Context/MainContext";
import LogoTsul from "../../assets/images/logo-tsul-dark.png"

const Login = props => {


    const history = useHistory();
    const {setRole_id, setusername} = useContext(MainContext);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isLogin, setIsLogin] = useState(false);



    const SignIn = () => {
        axios({
            url:"/login",
            method:"POST",
            data:{
                username,
                password
            }
        }).then((response) => {
            if(response?.data?.status === 1){
              setIsLogin(false)
                setRole_id(String(response?.data?.user?.role_id))
                setusername(response?.data?.user?.first_name)
                localStorage.setItem("token", response?.data?.access_token)
                history.push("/");
            }
        }).catch(response=>{
          setIsLogin(true)
        })
    }
    


  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages  py-sm-5">
        <Container>
          <Row className="justify-content-center mt-5">
            <Col md={8} lg={6} xl={5}>
              {
                isLogin?
                <h3 className="text-danger position-absolute">Parol yoki Login xato !</h3>:""
              }
              <Card className="overflow-hidden mt-5">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">TSUL</h5>
                        <p>Reseption</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title text-dark rounded-circle bg-white">
                          <img height="50" src={LogoTsul}/>
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="form-group">
                        <AvField
                          name="username"
                          label="Login"
                          value={username}
                          onChange={e => setUsername(e.target.value)}
                          className="form-control"
                          placeholder="username"
                          type="text"
                          required
                        />
                      </div>

                      <div className="form-group">
                        <AvField
                          name="password"
                          label="Parol"
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                          type="password"
                          required
                          placeholder="password"
                        />
                      </div>

                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customControlInline"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customControlInline"
                        >
                          Saqlab qo'yish
                        </label>
                      </div>

                      <div className="my-3">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                          onClick={SignIn}
                        >
                          Kirish
                        </button>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} TSUL. Created with  by EUM
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}



export default Login;