import React, { useState } from 'react';
import { Badge, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { isChecked } from "../../services/additional_functions/index"
import { NavLink, useHistory } from "react-router-dom"
import { delete_data } from "../../services/api_services"
import {Modal} from "antd"
import Styles from "./style.module.scss"


const ShowReception = ({ updateStateIndex, setUpdateStateIndex, isOpen, setIsOpen, data }) => {

    const [updateState, setUpdateState] = useState(false)
    const [isModalVisibleImage, setIsModalVisibleImage] = useState(false);
    // const [updateState, setUpdateState] = useState(false)

    const deleteItem = async path => {
        await delete_data(path).then(() => {
            setUpdateState(!updateState);
            setIsOpen(false)
            setUpdateStateIndex(!updateStateIndex)
        })
    }



    return (
        <React.Fragment>
            <ModalHeader toggle={() => setIsOpen(!isOpen)}>Mehmon</ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-5">
                        <p>
                            <span style={{ fontWeight: 600 }}>F.I.SH:&nbsp;&nbsp;</span>
                            <span>{data?.fio}</span>
                        </p>
                        <p>
                            <span style={{ fontWeight: 600 }}>Tekshirilganligi:&nbsp;&nbsp;</span>
                            <span>
                                <Badge
                                    color={isChecked(data?.checked)?.color}
                                    className="py-1 px-2 badge badge-pill">
                                    {isChecked(data?.checked)?.title}
                                </Badge>
                            </span>
                        </p>
                        <p>
                            <span style={{ fontWeight: 600 }}>Tashrif sababi:&nbsp;&nbsp;</span>
                            <span>{data?.reason}</span>
                        </p>
                        <p>
                            <span style={{ fontWeight: 600 }}>Tashrif haqida:&nbsp;&nbsp;</span>
                            <span>{data?.description}</span>
                        </p>
                        <p>
                            <span style={{ fontWeight: 600 }}>Tashrif vaqti:&nbsp;&nbsp;</span>
                            <span>{data?.date}</span>
                        </p>
                       
                    </div>
                    <div className="col-6">
                          <img style={{cursor:"pointer"}} onClick={()=>setIsModalVisibleImage(true)} className="w-100" src={"http://api-reception.tsul.uz" + data?.photo_come} alt="Rasm topilmadi" />
                    </div>
                    <div className="col-5">
                        <p className="d-flex justify-content-between mt-3">
                            <div>
                                <span style={{ fontWeight: 600 }}>Yaratilgan vaqti</span>
                                <p>{new Date(data?.created_at).toLocaleDateString("RU-ru")}</p>
                            </div>
                        </p>
                    </div>
                    <div className="col-6">
                        <p className="d-flex justify-content-between mt-3">
                            <div>
                                <span style={{ fontWeight: 600 }}>O'zgartirilgan vaqti</span>
                                <p>{new Date(data?.updated_at).toLocaleDateString("RU-ru")}</p>
                            </div>
                        </p>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <NavLink
                    to={{
                        pathname: `/edit_reception/${data.id}`,
                        state: {
                            title: data?.fio,
                        },
                    }}
                >
                    <button

                        disabled={data?.checked === 2 ? false : true}
                        className="btn btn-success" ><i className="fa fa-edit"></i> O'zgartirish</button>
                </NavLink>
                <button
                    disabled={data?.checked === 2 ? false : true}
                    className="btn btn-danger"
                    onClick={() =>
                        deleteItem(`/rec/${data?.id}`)
                    }
                ><i className="fa fa-trash-alt"></i> O'chirish</button>
                <button className="btn btn-light" onClick={() => setIsOpen(false)}>Bekor qilish</button>
            </ModalFooter>

            <Modal centered zIndex={10000} bodyStyle={{padding:"0"}} className="p-0" closeIcon={<i className={`${Styles.closeButton} fas fa-times text-white`}></i>} width={1000} title="" visible={isModalVisibleImage} onCancel={()=>setIsModalVisibleImage(false)} footer={false} >
                <img src={"http://api-reception.tsul.uz" + data?.photo_come} className="w-100" alt="" />
            </Modal>
        </React.Fragment >
    )
}


export default ShowReception;