import PropTypes from 'prop-types'
import React from "react"

import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { kadrRoutes, authRoutes } from "../routes/allRoutes"

// Import all middleware
import Authmiddleware from "../routes/middleware/Authmiddleware"

// layouts Format
import HorizontalLayout from "../components/HorizontalLayout/"
import NonAuthLayout from "../components/NonAuthLayout"

// Import scss
import "../assets/scss/theme.scss"


const KadrUser = props => {
    const getLayout = () => HorizontalLayout;

    const Layout = getLayout()
    return (
        <React.Fragment>
            <Switch>
                {authRoutes.map((route, idx) => (
                    <Authmiddleware
                        path={route.path}
                        layout={NonAuthLayout}
                        component={route.component}
                        key={idx}
                    />
                ))}

                {kadrRoutes.map((route, idx) => (
                    <Authmiddleware
                        path={route.path}
                        layout={HorizontalLayout}
                        component={route.component}
                        key={idx}
                        exact
                    />
                ))}
            </Switch>
        </React.Fragment>
    )
}

KadrUser.propTypes = {
    layout: PropTypes.any
}

const mapStateToProps = state => {
    return {
        layout: state.Layout,
    }
}

export default connect(mapStateToProps, null)(KadrUser)
