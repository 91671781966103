import React, { useState, useEffect } from "react"
import { Card, CardBody, Container } from "reactstrap"
import { Form, Input, Select, Radio, Switch } from "antd"
import axios from "axios"
import Swal from "sweetalert2/dist/sweetalert2.js"
import "sweetalert2/src/sweetalert2.scss"
import Styles from "./style.module.scss"
import { NavLink, useHistory } from "react-router-dom"
const AddUser = () => {

  const  history  = useHistory()
  const { Option } = Select
  const [form] = Form.useForm()
  const [radio, setRadio] = React.useState(1)
  const [department, setDepartment] = useState([])
  const [role, setRole] = useState([])
  const [chekced, setChecked] = useState(true)
  const [validator_errors, setSalidator_errors] = useState({})

  const onChangeRadio = e => {
    setRadio(e.target.value)
  }
  const postUsers = values => {
    const token = localStorage.getItem("token")
    values.status = values.status ? 1 : 0
    axios({
      url: "/user",
      method: "POST",
      params: {
        token,
      },
      data: values,
    }).then(response => {
        if(response?.data?.status === 1){
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Foydalanuvchi qo'shildi",
                showConfirmButton: false,
                timer: 1000,
            }).then(()=>{
                history.goBack()
            })
      }else{
          setSalidator_errors(response?.data?.validator_errors)
      }
    })
    
  }

  const onReset = () => {
    form.resetFields()
  }
  useEffect(() => {
    const token = localStorage.getItem("token")
    axios({
      url: "/department",
      method: "GET",
      params: {
        token,
      },
    }).then(response => {
      setDepartment(response.data.data)
    })
    axios({
      url: "/get-role",
      method: "GET",
      params: {
        token,
      },
    }).then(response => {
      setRole(response.data.data)
    })
  }, [])

  form.setFieldsValue({
    role_id: 1,
    department_id: 1,
    status: chekced,
    gender: 1,
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card style={{ minHeight: "80vh" }}>
            <CardBody>
              <Form form={form} name="control-hooks" onFinish={postUsers}>
                <div className="row label">
                  <div className="col-md-4 col-sm-6 col-12">
                    <label><span className={Styles.red_item}>*</span>Ism</label>
                    <Form.Item
                      name="first_name"
                      className="w-100"
                      rules={[
                        {
                          required: true,
                          message: "Ismni kiriting!"
                        }
                      ]}
                    >
                      <Input
                        placeholder="Ismni kiriting"
                        className="w-100 ant-input-height"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <label><span className={Styles.red_item}>*</span>Familiya</label>
                    <Form.Item
                      name="last_name"
                      className="w-100"
                      rules={[
                        {
                          required: true,
                          message: "Familiyani kiriting!"
                        }
                      ]}
                    >
                      <Input
                        placeholder="Familiyani kiriting"
                        className="w-100 ant-input-height"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <label><span className={Styles.red_item}>*</span>Sharif</label>
                    <Form.Item
                      name="middle_name"
                      className="w-100"
                      rules={[
                        {
                          required: true,
                          message: "Sharifni kiriting!"
                        }
                      ]}
                    >
                      <Input
                        placeholder="Sharifni kiriting"
                        className="w-100 ant-input-height"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <label><span className={Styles.red_item}>*</span>RoleID</label>
                    <Form.Item
                      name="role_id"
                      className="w-100"
                      rules={[
                        {
                          required: true,
                          message: "Rolni tanlang!"
                        }
                      ]}
                    >
                      <Select
                      placeholder="Rolni tanlang"
                        allowClear
                        className="w-100 ant-input-height"
                      >
                        {role.map(item => (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <label><span className={Styles.red_item}>*</span>Position</label>
                    <Form.Item
                      name="position"
                      className="w-100"
                      rules={[
                        {
                          required: true,
                          message: "Sababini kiriting!"
                        }
                      ]}
                    >
                      <Input
                        placeholder="Position"
                        className="w-100 ant-input-height"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <label><span className={Styles.red_item}>*</span>Department</label>
                    <Form.Item
                      name="department_id"
                      className="w-100"
                      rules={[
                        {
                          required: true,
                          message: "Bo'limni tanlang!"
                        }
                      ]}
                    >
                      <Select
                      placeholder="Bo'limni tanlang"
                        allowClear
                        className="w-100 ant-input-height"
                      >
                        {department.map(item => (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="col-md-2 col-sm-6 col-6 pt-4">
                    <Form.Item name="gender">
                      <Radio.Group>
                        <Radio value={1}>Erkak</Radio>
                        <Radio value="0">Ayol</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                  <div className="col-md-2 col-sm-6 col-6 pt-4 status-label">
                    <Form.Item name="status" label="Status">
                      <Switch checked={chekced} onChange={()=>{setChecked(!chekced)}}/>
                    </Form.Item>
                  </div>
                </div>
                <div className="float-right mt-2">
                  <Form.Item>
                    <button className="btn btn-primary" htmlType="submit">
                      Saqlash
                    </button>
                    <button
                      htmlType="button"
                      className="btn btn-warning mx-3 warning_btn"
                      onClick={onReset}
                    >
                      Tozalash
                    </button>
                    <NavLink to="/users">
                    <button type="button" className="btn btn-success">
                      Orqaga
                    </button>
                    </NavLink>
                  </Form.Item>
                </div>
                <div style={{ clear: "both" }}></div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AddUser
