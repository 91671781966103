import React, { useEffect, useState } from "react"
import { Card, CardBody, Container } from "reactstrap"
import Styles from "./style.module.scss"
import axios from "axios"
import { Modal, Form, Input} from "antd"
import Swal from "sweetalert2/dist/sweetalert2.js"
import { useHistory } from "react-router"
import { NavLink } from "react-router-dom"
const ProfilePage = () => {
  const history = useHistory()
  const [idd, setIdd] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isDanger, setIsDanger] = useState(false)
  const [data, setData] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem("token")
    axios({
      url: "/me",
      method: "POST",
      params: {
        token,
      },
    }).then(response => {
      setIdd(response?.data?.data?.id)
      setData(response?.data?.data)
    })
  }, [])

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const onFinish = values => {
    console.log("dddddddddd==>>", values.password, values.password2)

    setIsDanger(values?.password !== values?.password2)

    var passwordData = new FormData()
    passwordData.append("old_password", values?.old_password)
    passwordData.append("password", values?.password)
    if (values?.password === values?.password2) {
      const token = localStorage.getItem("token")
      axios({
        url: `/change-password`,
        method: "POST",
        params: {
          token,
        },
        data: passwordData,
      }).then(response => {
        console.log("esponseeeeee=>",response?.data);
        if(response?.data?.status===1){
          localStorage.setItem("token", response?.data?.access_token)
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Paroll O'zgartirildi!",
            showConfirmButton: false,
            timer: 1500,
          })
        }
       
      })
    } else {
      setIsModalVisible(true)
    }
  }

  return (
    <Container fluid>
      <div className="page-content">
        <Card>
          <CardBody>
            <Card>
              <CardBody>
                <div className="row">
                  <div className="col-md-1 col-6">
                    <div className={Styles.profile_rounder}>
                      <i className="bx bx-user mr-2 font-size-24"></i>
                    </div>
                  </div>
                  <div className="col-md-4 col-6">
                    <div>
                      <label className=" font-size-14 text-secondary">
                        Ism:
                      </label>
                      {/* <Input disabled={true} className="ant-input-height" /> */}
                      <h5 className="font-weight-bold">{data?.first_name}</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-6">
                    <div>
                      <label className=" font-size-14 text-secondary">
                        Familiyasi:
                      </label>
                      {/* <Input disabled={true} className="ant-input-height" /> */}
                      <h5 className="font-weight-bold">{data?.last_name}</h5>
                    </div>
                  </div>
                  <div className="col-md-3 col-6">
                    <div>
                      <label className=" font-size-14 text-secondary">
                        Sharifi:
                      </label>
                      {/* <Input disabled={true} className="ant-input-height" /> */}
                      <h5 className="font-weight-bold">{data?.middle_name}</h5>
                    </div>
                  </div>
                  <div className="col-md-1 col-6"></div>
                  <div className="col-md-4 col-6 mt-2">
                    <div>
                      <label className=" font-size-14 text-secondary">
                        UserName:
                      </label>
                      {/* <Input disabled={true} className="ant-input-height" /> */}
                      <h5 className="font-weight-bold">{data?.username}</h5>
                    </div>
                  </div>
                  <div className="col-md-4 col-6">
                    <button className="btn btn-light mt-3" onClick={showModal}>
                      Parolni o'zgartirish
                    </button>
                  </div>
                  {
                    data?.role_id===1?
                  <div className="col-md-3 col-6">
                    <NavLink to="/">
                    <button onClick={()=>{history.goBack()}} className="btn btn-light mt-3" onClick={showModal}>
                      Orqaga
                    </button>
                    </NavLink>
                  </div>
                  :""
                  }
                </div>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      </div>
      <Modal
        title="Parolni o'zgartirish"
        visible={isModalVisible}
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
      >
        {isDanger ? <h4 className="text-danger">Moslik topilmadi !</h4> : ""}
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <label>
            <span className="text-danger mr-1">*</span>Joriy parol
          </label>
          <Form.Item
            name="old_password"
            rules={[{ required: true, message: "Joriy parolni kiriting!" }]}
          >
            <Input.Password
              placeholder="Joriy parol"
              maxLength={16}
              minLength={6}
            />
          </Form.Item>
          <label>
            <span className="text-danger mr-1">*</span>Yangi parol
          </label>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Yangi parolni kiriting!" }]}
          >
            <Input.Password
              placeholder="Yangi parol"
              maxLength={16}
              minLength={6}
            />
          </Form.Item>
          <label>
            <span className="text-danger mr-1">*</span>Yangi parolni takrorlang
          </label>
          <Form.Item
            name="password2"
            rules={[
              { required: true, message: "Yangi parolni takroran kiriting!" },
            ]}
          >
            <Input.Password
              placeholder="Yangi parolni takrorlang"
              maxLength={16}
              minLength={6}
            />
          </Form.Item>

          <Form.Item className="text-right">
            <button
              className="btn btn-success mt-3"
              htmlType="submit"
              // onClick={onFinish}
            >
              Saqlash
            </button>
            <button className="btn btn-danger ml-2 mt-3" onClick={handleCancel}>
              Bekor qilish
            </button>
          </Form.Item>
        </Form>
      </Modal>
    </Container>
  )
}
export default ProfilePage
