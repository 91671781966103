import React from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"
import Dashboard2 from "./Dashboard-saas"

const Dashboard = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid >
            <Card>
              <CardBody>
                <Dashboard2 />
              </CardBody>
            </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
