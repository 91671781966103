import React, { useState, useEffect, useContext } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import axios from "axios"
import { withTranslation } from "react-i18next"
import { withRouter, Link, useHistory } from "react-router-dom"
import MainContext from "../../../Context/MainContext"

const ProfileMenu = props => {

  const history = useHistory()
  const [menu, setMenu] = useState(false)
  const { setRole_id, username } = useContext(MainContext)

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
      }
    }

  }, [props.success])

  const logout = () => {
    const token = localStorage.getItem("token")
    axios({
      url: "/logout",
      method: "POST",
      params: {
        token: token,
      },
    })
      .then(response => {
        localStorage.clear()
        history.push("/login")
        setRole_id("")
      })
      .catch(error => {
        if (error.response.status === 401) {
          history.push("/login")
          localStorage.clear()
        }
      })
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className="bg-light text-secondary rounded-circle p-2">
            <i className="bx bx-user font-size-20"></i>
          </span>
          {/* <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          /> */}
          <span className="d-none d-xl-inline-block ml-2 mr-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu right>
          <Link to="/profile">
            <DropdownItem>
              <i className="bx bx-user font-size-16 align-middle mr-1" />
              {props.t("Profile")}{" "}
            </DropdownItem>
          </Link>
          <DropdownItem
            className=""
            style={{ cursor: "pointer" }}
            onClick={() => logout()}
          >
            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger" />
            <span>{props.t("Chiqish")}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(ProfileMenu))
