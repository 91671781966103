import React, { useState,useEffect } from 'react';
import { Col, Container, Row, Table,Button } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from "axios"
import Styles from  "./style.module.scss"
import Swal from 'sweetalert2'
import DataLoader from '../Loaders/DataLoaders';
const Security = () => {

const [data, setData] = useState([]);
const [isLoading, setIsLoading] = useState(false)
const [isGet, setIsGet] = useState(false)
const [isInside, setIsInside] = useState(false)

useEffect(()=>{
  setIsLoading(true)
    const token = localStorage.getItem("token")
    axios({
        url: "/guard",
        method: "GET",
        params: {
          token,
        },
      }).then(response => {
        console.log(response?.data);
        setIsLoading(false)
          if(response?.data?.status===0){
              alert(response?.data?.message)
          }else{
              setData(response?.data?.data)
          }
      })
      // let res = data.filter(i => i.fio.toLowerCase().includes(inputVal.toLowerCase()))
      // setData(res)
},[isGet])


// const seaech = ()

// const filterItems = () => {
//   let res = data.filter(i => i.fio.toLowerCase().includes(inputVal.toLowerCase()))
//   setData(res)
// }

// const dataFilter = (e) => {
//   setInputVal(e.target.value);
// }

const checkedToCome = (id) => {
    const token = localStorage.getItem("token")
    axios({
        url: `/come/${id}`,
        method: "POST",
        params: {
          token
        },
      }).then(response => {
        console.log("come",response)
        setIsGet(!isGet)
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: response?.data?.message,
          showConfirmButton: false,
          timer: 1500
        })
      })
}

const checkedToGone = (id) => {
    const token = localStorage.getItem("token")
    axios({
      url: `/gone/${id}`,
      method: "POST",
      params: {
      token
    },
    }).then(response => {
      console.log("gone",response)
      setIsGet(!isGet)
      setIsInside(true)
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: response?.data?.message,
        showConfirmButton: false,
        timer: 1500
      })
    })
  }

    return (
        <div className="page-content pt-5">
      <Container fluid>
        <Row>
          <Col lg="12">
              <div className="table-responsive">
                {
                  !isLoading?
                <Table className="project-list-table table-nowrap table-centered table-borderless">
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: "100px" }}>#</th>
                      <th scope="col">F.I.Sh</th>
                      <th scope="col">Sababi</th>
                      <th scope="col">Kirgan vaqti</th>
                      <th scope="col" className={Styles.center}>Amallar</th>
                      <th scope="col">Chiqib ketgan vaqti</th>
                    </tr>
                  </thead>
                  <tbody >
                    {data?.map((item,index)=> (
                      <tr key={item.id} className={`${item.checked===0?Styles.bg_red:item.checked===1?Styles.bg_green:Styles.bg_warning}`}>
                        <td>{index+1}</td>
                        <td>
                          <h5 className={Styles.color_black} >
                              {item.fio}
                          </h5>
                        </td>
                        <td>{item.reason}</td>
                        <td>
                          {item.come?.slice(0, 10)}{"  "}{item.come?.slice(11, 16)}
                        </td>
                        <td className="text-center font-size-14 p-0">
                        {
                          console.log(item),
                          item.checked===2?"Hali ko'rilmagan":
                          item.checked===0?"Ruxsat berilmagan":
                          item.come===null?
                          <button
                            id="gg"
                            className="btn btn-light text-dark Mybutton mr-2 w-75"
                            disabled={item.checked===1 ? false:true}
                             onClick={()=>checkedToCome(item.id)}>Kirdi</button>
                          :item.gone===null?
                             <button
                          
                            id="gg"
                            className="btn btn-light text-dark Mybutton w-75"
                            disabled={item.checked===1 ?false:true}
                             onClick={()=>checkedToGone(item.id)}>Chiqdi</button>: <i className="fas fa-check text-success"/>
                          
                        }
                        </td>
                        <td>{item.gone?.slice(0,10)}{"  "}{item.gone?.slice(11,16)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>:<DataLoader />
                }
              </div>
          </Col>
        </Row>
      </Container>
    </div>
    )
}


export default Security;