import React, { Component } from "react"
import { Container, Row, Col } from "reactstrap"
import CardWelcome from "./card-welcome"
import MiniWidget from "./mini-widget"
import TotalSellingProduct from "./total-selling-product"

class Dashboard2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [
        {
          icon: "bx bx-copy-alt",
          title: "Kelganlar",
          value: "145 ta",
          badgeValue: "145",
          color: "success",
          desc: "Universitet hududidagilar  ",
        },
        {
          icon: "bx bx-archive-in",
          title: "Ketganlar",
          value: "98 ta",
          badgeValue: "- 98",
          color: "danger",
          desc: "Chiqib ketganlar",
        },
        {
          icon: "bx bx-purchase-tag-alt",
          title: "Kutilyotganlar",
          value: "34 ta",
          badgeValue: "+ 34",
          color: "warning",
          desc: "Kelishi kutilyotganlar",
        },
      ],
    }
  }


  

  render() {
    return (
      <React.Fragment>
        <Row>
          <CardWelcome />
          <Col xl="8">
            <Row>
              <MiniWidget reports={this.state.reports} />
            </Row>
          </Col>
          <TotalSellingProduct />
        </Row>
      </React.Fragment>
    )
  }
}

export default Dashboard2
